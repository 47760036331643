//import web3 from "./web3"
import * as utils from "./utils"
import * as contracts from "./contracts"
import * as events from "./events"
import * as summary from "./summary"

export function getFouContract(address) {
    return(new web3.eth.Contract(FrendOweYou.abi, address))}

//FRENDOWEYOU RELATED CALLS
export async function getFou(address) {
    
    const fouContract = contracts.getFouContract(address)
    const summary = await fouContract.methods.getSummary().call();
    const fouPayments = await events.getFouPayments(address)    
    const fouFunded = await events.getFouFunded(address)
    const fouRejected = await events.getFouRejected(address)
    const created = new Date(summary._created * 1000);
    return {
      currentWallet: utils.myWallet,
      address: address,
      frendLend: summary._frendLend,
      lender: summary._lender,
      recipient: summary._recipient,
      status: summary._status,
      requested: utils.fromWeiToEther(summary._requested),
      funded: fouFunded,
      rejected: fouRejected,
      repaid: utils.fromWeiToEther(summary._repaid),
      payments: fouPayments,
      created: created
    };
  }
  
  export async function getFous(addresses) {
    const fous = addresses.map(getFou);
    return Promise.all(fous);
  }
  export async function createFou(address, amount) {
    const flContract = contracts.getFrendLendContract(address)
    const amountWei = utils.toWeiFromEther(amount);
    const receipt = await flContract.methods
      .createFou(amountWei)
      .send({ from: utils.myWallet });
    console.log("createFou receipt: ", receipt)
    return receipt.events.NewFrendOweYou.returnValues.newContract;
  }
  export async function fundFou(address) {
    const fouContract = contracts.getFouContract(address)
    const fou = await getFou(address);  
    const receipt = await fouContract.methods.fundFou().send(
      {
        from: utils.myWallet,
        value: utils.toWeiFromEther(fou.requested)
      },
      function(error, txHash) {
        console.log("Error: " + error);
        return error;
      }
    );
    return receipt;
  }
  export async function rejectFou(address) {
    const fouContract = contracts.getFouContract(address)
    const receipt = await fouContract.methods.rejectFou().send({
      from: utils.myWallet
    });
    return receipt;
  }
  export async function repayFou(address, amount) {
    const fouContract = contracts.getFouContract(address)
    const receipt = await fouContract.methods.repayFou().send({
      from: utils.myWallet,
      value: utils.toWeiFromEther(amount)
    });
    return receipt;
  }