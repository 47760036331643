namespace Pages

open Feliz
open Elmish
open Feliz.ElmishComponents
open Feliz.Bulma
open Feliz.Router

open Domain
open Domain.Ethereum
open Domain.FrendLends
open Common.Interop.Ethereum    
open Common.Components
open Common

module Home =    
    type Msg =
        | CreateFrendLend
        | FrendLendCreated of string
        | GetFrendLends
        | GotFrendLends of string array
        | Failure of exn
    type Model = {
        FrendLends : FrendLend list 
        CurrentWallet : EthAddress
        CreatingFrendLend : bool 
        LoadFailed : exn option }
        with
        static member Init = {
            FrendLends = []
            CurrentWallet = web3.myWallet |> EthAddress.fromOption
            CreatingFrendLend=false
            LoadFailed = None}
    module Model =
        let init = Model.Init, Cmd.ofMsg GetFrendLends   
    
        let update msg (model:Model) : Model * Cmd<Msg> =
            match msg with 
            | CreateFrendLend ->
                let createCmd = 
                    Cmd.OfPromise.either 
                        createFrendLend () FrendLendCreated Failure        
                {model with CreatingFrendLend = true}, createCmd
            | FrendLendCreated response ->
                printfn "FrendLend Create Response: %O" response                
                {model with CreatingFrendLend = false}, Router.navigate("frendlend", response)          
            | GetFrendLends ->             
                let getCmd =                 
                    Cmd.OfPromise.either getFrendLends () (GotFrendLends) (fun e -> GotFrendLends[|e.Message|])
                model, getCmd
            | GotFrendLends response -> 
                printfn "FrendLend Response: %O" response
                let frendLends = 
                    response |> Array.map EthAddress 
                    |> List.ofArray
                    |> List.map (fun a -> {FrendLend.empty with Address = a})
                {model with FrendLends = frendLends}, Cmd.none
            | Failure e ->     
                {model with LoadFailed = Some e }, Cmd.none        

        let render model (dispatch: Msg -> unit) =
            let splashTitle = Bulma.title "Existing FrendLend Groups"
            let walletActivity = 
                WalletActivity.render model.CurrentWallet
                |> narrowColumn
            let frendLendLinks =
                model.FrendLends
                |> List.map (fun lg ->
                    let addr = lg.Address.AsString
                    let url = sprintf "#/frendlend/%s" addr
                    Link.create addr "View Group Details" url false None
                    |> Link.renderBoxLink)                                      
            let addLendGrpBtn =
                let loading = if model.CreatingFrendLend then BulmaCss.IsLoading else ""
                [ prop.classes [BulmaCss.IsPrimary; BulmaCss.Button; loading]
                  prop.onClick (sendMsg dispatch CreateFrendLend)
                  prop.children [ 
                      Html.span "Create FrendLend"                      
                      Icon.createSpan PlusSign (PropClasses [BulmaCss.Icon; BulmaCss.IsSmall]) ]] 
                |> htmlFunc Html.button
            let existingFrendLends = 
                [splashTitle] @ [addLendGrpBtn] @ frendLendLinks 
                |> Html.div |> narrowColumn
                     
            let content =
                match model.LoadFailed with
                | Some e -> [Html.h1 (sprintf "Page Failed to Load: " + e.Message)]
                | None -> 
                    [Bulma.columns [ prop.children [walletActivity; existingFrendLends]]]
            Bulma.section [ 
                Bulma.container content]
        let elmishComp = React.elmishComponent("HomeComponent", init, update, render)
       