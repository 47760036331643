namespace Common

open System
open Feliz
open Elmish
open Feliz.ElmishComponents
open Fable.Core
open Fable.Core.JsInterop
open Fable.Core.JS
open Domain.FrendLends
module Interop =
    
    [<Emit("undefined")>]
    let undefined : obj = jsNative
    module Ethereum =
    
        let [<Literal>] FrendLendJs = "./jsfiles/frendlend.js" 
        let [<Literal>] FrendOweYouJs = "./jsfiles/frendoweyou.js" 
        let [<Literal>] UtilsJs = "./jsfiles/utils.js"
        let [<Literal>] SummaryJs = "./jsfiles/summary.js"
        let [<Literal>] Web3Js =  "./jsfiles/web3.js"
        type IWeb3 =
            abstract myNetwork : string
            abstract myWallet : string option
            abstract enableMetamask : unit -> Promise<unit>
            abstract ethWallet : unit -> string
        let web3 : IWeb3 = importAll Web3Js  
        //let myWallet : string option = importMember UtilsJs
        type ITransfer = { amount : float; blocktime : DateTime}
        type IFrendOweYou =
            { address : string
              frendLend : string
              lender : string
              recipient : string 
              requested : float
              repaid : float
              status : int
              rejected : ({|message : string; blocktime: DateTime|}) [] 
              funded : (ITransfer)[] 
              payments :  ITransfer []
              created : DateTime }
            member x.toDomain =
                let payments = 
                    x.payments |> List.ofArray 
                    |> List.map (fun p -> Transfer.fromPrims x.recipient x.lender p.amount p.blocktime)
                let funding = 
                    let funded = x.funded |> List.ofArray
                    let rejected = x.rejected |> List.ofArray
                    match funded, rejected with 
                    | f::_ , [] -> 
                        Transfer.fromPrims x.recipient x.lender f.amount f.blocktime
                        |> Funded
                    | [], r::_ -> 
                        {Message = r.message; Created = r.blocktime
                        } |> Rejected
                    | _ -> 
                        Pending
                FrendOweYou.fromPrims 
                    x.address x.frendLend x.lender x.recipient 
                    x.requested x.repaid x.status funding payments x.created
        let createFou (addr:string, amt:float) : Promise<string> =
            importMember FrendOweYouJs                  
        let getFous : string[] -> Promise<IFrendOweYou[]> = importMember FrendOweYouJs
        let getFou : string -> Promise<IFrendOweYou option> = importMember FrendOweYouJs
        let fundFou : string -> Promise<string> = importMember FrendOweYouJs
        let repayFou (addr:string, amt:float) : Promise<string> = 
            importMember FrendOweYouJs
        let rejectFou : string -> Promise<string>= 
            importMember FrendOweYouJs        

        type IFrendLend = 
            { address : string  
              lender : string
              frendOweYous : IFrendOweYou []}
            member x.toDomain =
                let fous = x.frendOweYous |> List.ofArray |> List.map  (fun f -> f.toDomain)
                FrendLend.fromPrims x.address x.lender fous
        let getFrendLend : string -> Promise<IFrendLend option> = importMember FrendLendJs        
        let getFrendLends : unit -> Promise<string []> = importMember FrendLendJs        
        let createFrendLend : unit -> Promise<string> = importMember FrendLendJs
            
        type IWalletActivity = 
            { wallet : string
              frendOweYous : IFrendOweYou []
              frendLends : IFrendLend [] }
            member x.toDomain =
                WalletActivity.fromPrims 
                    x.wallet
                    (x.frendLends |> List.ofArray |> List.map (fun f -> f.toDomain))
                    (x.frendOweYous |> List.ofArray |> List.map (fun f -> f.toDomain))
        let getWalletActivity : string -> Promise<IWalletActivity option> = 
            importMember SummaryJs              
    module Promises =
        type PromiseStatus =
            | Requesting
            | Received
            | FailedResponse
            | Pending
        type PromiseMsg<'arg, 'resp> =
            | MakeRequest of 'arg
            | Response of 'resp
            | Failed of exn:string
        type PromiseModel<'arg, 'resp> = {  
            Status : PromiseStatus
            Arguments : 'arg
            Response : 'resp
            Failed : string }

        [<RequireQualifiedAccess>]
        module PromiseModel =
            let log prefix postfix =
                printfn "%O %s: %O" prefix (DateTime.Now.ToString()) postfix
            let init initCmd (args:'a) (resp:'b) : (PromiseModel<'a,'b> * Cmd<PromiseMsg<'a,'b>>) = 
                {Status = Pending; Arguments = args; Response = resp; Failed = ""}, 
                initCmd          
            let initPromise reqFunc args resp =
                {Status = Pending; Arguments = args; Response = resp; Failed = ""},
                Cmd.OfPromise.either reqFunc args MakeRequest (fun e -> Failed e.Message)

            let update reqFunc respCmd (msg:PromiseMsg<'a,'b>) (model:PromiseModel<'a,'b>) : (PromiseModel<'a,'b> * Cmd<PromiseMsg<'a,'b>>) =
                match msg with
                | MakeRequest args ->
                    log "Make Request" args
                    {model with Status = Requesting; Arguments = args}, 
                    Cmd.OfPromise.either
                        reqFunc args Response (fun e -> Failed e.Message)
                | Response response -> 
                    log "Response" response
                    {model with Status = Received; Response = response}, respCmd
                | Failed failed ->   
                    log "Failed Promise" failed
                    {model with Status = FailedResponse; Failed = failed}, Cmd.none
           