import * as frendlend from "./frendlend"
import * as frendoweyou from "./frendoweyou"
import * as events from "./events"
import * as utils from "./utils"

async function getWalletFrendLends(lenderWallet) {
    const allDetails =  await frendlend.getFrendLendDetails()
    const detailsPromise = await Promise.all(allDetails)  
    let filtered = detailsPromise.filter(details => 
        details.lender.toLowerCase() == lenderWallet.toLowerCase())
    console.log("all frendlends: ",filtered, "lenderWallet: ", lenderWallet)
    return filtered
}

async function getWalletFous(borrowerWallet){
    const allDetails =  await frendlend.getFrendLendDetails()
    const allFous = allDetails.flatMap(details => details.frendOweYous)
    const filtered = allFous.filter(details =>
        details.recipient.toLowerCase() == borrowerWallet.toLowerCase() )
    console.log("all fous: ", filtered)
    return (filtered)
}

export async function getWalletActivity(wallet){
    return {
        wallet: wallet, 
        frendOweYous: await getWalletFous(wallet),
        frendLends: await getWalletFrendLends(wallet)
    }
}
//getWalletActivity(utils.myWallet).then(console.log)