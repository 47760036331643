import * as utils from "./utils"
import * as contracts from "./contracts"

const fromBlock = sessionStorage.fromBlock
async function fouEvents(address, eventName) {
    const fouContract = contracts.getFouContract(address)
    return await fouContract.getPastEvents(eventName, {fromBlock:fromBlock})    
}

function transferDetails(event) {
    const eventVals = event.returnValues
    return {
        amount: utils.fromWeiToEther(eventVals.amount),
        blocktime: new Date(eventVals.blocktime*1000)
    }
}

export async function getFouPayments(address) {    
    const payments = await fouEvents(address, "Payment")
    return payments.map(transferDetails) 
}


export async function getFouFunded(address) {
    let funded = await fouEvents(address, "Funded")    
    return funded.map(transferDetails)
}

function rejectedDetails(event) {
    const rejected = event.returnValues
    return {
        message: "not yet implemented",
        blocktime: new Date(rejected.blocktime*1000)
    }
}
export async function getFouRejected(address) {
    const rejected =  await fouEvents(address, "Rejected")    
    return rejected.map(rejectedDetails)
}