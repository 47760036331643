namespace Pages.CustomComps

open System
open Feliz
open Feliz.Router
open Elmish
open Feliz.ElmishComponents
open Domain.Ethereum
open Domain.FrendLends
open Common.Interop.Ethereum
open Common.Components
open Common
open TableBuilder
open Feliz.Bulma.Tooltip

module AddressLabel =
    type ContractType =
        | FrendLendC 
        | FrendOweYouC of frendLend:EthAddress        

    type AddressType =
        | Wallet
        | EthContract of ContractType
        | Unknown
        | NoAddress
    
    type AddressLabel = {
        Address : EthAddress 
        AddressType : AddressType 
        } with
        member x.DetailsRoute =
            match x.AddressType with
            | Wallet -> Router.format("walletdetails",x.Address.AsString)
            | EthContract c -> 
                match c with 
                | FrendLendC -> Router.format("frendlend", x.Address.AsString)
                | FrendOweYouC fl -> 
                    Router.format("frendlend", fl.AsString, ["fou", x.Address.AsString])
            | _ -> ""

    module AddressLabel =
        let create addr typ = {Address = addr; AddressType = typ}
        let render = React.functionComponent("EthAddressComp", fun (addrLbl:AddressLabel) ->
            let addr = addrLbl.Address.AsString
            let copy = CopyLink.render addr           
            //let labelProps
            let label = 
                match addrLbl.AddressType with
                | Wallet | EthContract _ ->
                    Html.a[                
                        tooltip.text addrLbl.Address.AsString
                        prop.style [style.color BulmaCss.IsPrimary; style.fontSize (length.percent 90 )]
                        prop.text addrLbl.Address.AsShortString
                        prop.href addrLbl.DetailsRoute]
                | _ -> 
                    Html.p[
                        tooltip.text addrLbl.Address.AsString
                        prop.style [style.color BulmaCss.IsPrimary; style.fontSize (length.percent 90 )]
                        prop.text addrLbl.Address.AsShortString ]                    
                
            addonFields [label; copy])

    type EthAddress with
        member x.AsAddressLabel typ =
            AddressLabel.create x typ  
        member x.Render typ =
            x.AsAddressLabel typ 
            |> AddressLabel.render



    

    