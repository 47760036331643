import * as myweb3 from "./web3"
import * as fou from "./frendoweyou"
import * as utils from "./utils"
import * as contracts from "./contracts"

myweb3.enableMetamask().then(console.log("enabling from frendlend.js"))
//I don't like this var mutation... 
let factoryAddress
switch(utils.myNetwork) {
    case "4": //rinkeby
        factoryAddress = "0xFA2A4D28E8d2330AfA9c22f2C2547584f02523Fe"
        console.log(factoryAddress)
        break;
    case "5778": //jeremy
        factoryAddress = "0xd02E3521E54458f2C232c2d7C4eF9Ce2F1487E4e"
        console.log(factoryAddress)
        break;
    case "5779": //jeremy laptop
        factoryAddress = "0xd1ACac9f4E66F79A6e3CAa915227D8C282eAACe0"
        console.log(factoryAddress)
        break;
    case "5777": //mike
        factoryAddress = "0x2857De154CF878Ca01a40A2545E2050EF68A784B"
        break;
    default:
        factoryAddress = "0x0395Dcc264959F73ED9AE8E9126A50F1397bC31E"
}

const flFactory = contracts.getFrendLendFactory(factoryAddress);

export async function getFrendLends() {
  const frendLends = await flFactory.methods.getFrendLends().call();
  return frendLends;
}

export async function createFrendLend() {
  const receipt = await flFactory.methods
    .createFrendLend()
    .send({ from: utils.myWallet });
  return receipt.events.NewFrendLend.returnValues.newContract;
}

export async function getFrendLend(address) {
  const flContract = contracts.getFrendLendContract(address)
  const flSummary = await flContract.methods.getSummary().call();
  const fous = await fou.getFous(flSummary.frendOweYous);
  const details = {
    currentWallet: utils.myWallet,
    address: address,
    lender: flSummary.lenderAddress,
    frendOweYous: fous
  };
  
  return details;
}

export async function getFrendLendDetails(){
    const allFrendlends = await getFrendLends()
    const allDetails = allFrendlends.map(getFrendLend)
    return Promise.all(allDetails)
}

//EVENT example!
// export async function getFrendOweYous(address) {
//     const flContract = new web3.eth.Contract(FrendLend.abi, address);
//     const events = await flContract.getPastEvents("NewFrendOweYou",{fromBlock: 0})
//     function getDetails(event) {
//         return {
//             amount: web3.utils.fromWei(event.returnValues.amount.toString(), 'ether'),
//             address: event.returnValues.newContract,
//             recipient: event.returnValues.recipient,
//             blocktime: new Date(event.returnValues.blocktime*1000)}
//     }
//     const eventDetails = events.map(getDetails)
//     return eventDetails
// }
