namespace Pages

open Feliz
open Elmish
open Feliz.ElmishComponents
open Feliz.Bulma
    
module About =
    type Model =
        {AboutText : string}
    module View =

        let render = React.functionComponent("frendLendHome", fun (model:Model) ->
            let aboutText = [
                Html.p"FrendLend is a ledger to keep track of lending to friends. It starts with you having a wallet and creating a FrendLend. When you create a FrendLend you own a contract that manages requests from other wallets (frends) to borrow ether. If you agree, you signal to your FrendLend contract to fund a 'FrendOweYou' and ether is sent from your wallet to that of your Friend. This activity is all captured in your FrendLend contract. A FrendLend contract will keep track of all requests, pending funding, funded, repaid and rejected requests."
                Html.br []
                Html.p "Friends can later repay a FrendOweYou by navigating to the original FrendLend request page and choose to pay back ether. The FrendLend contract updates to repaid and you get your lent ether returned to your wallet."
                Html.br []
                Html.p "The idea is to allow people who know each other (and their respective wallets) to lend while keeping track of things."
                Html.br []
                Html.p "FrendLend is a basic use case for solidity contracting. We did it to demonstrate a 'stem to stern' application of web3 inside of a simple web front end."
                Html.br []
                Html.p "FrendLend is a collaboration of Mike Revy and Jeremy Frank"
                Html.br []
                Html.p "last release 3/27/2020 3:27pm MT"
            ]
            Bulma.section [                
                Bulma.container [
                    Bulma.title "About FrendLend"
                    Html.div aboutText]]  )