namespace Common

open System
open FsToolkit.ErrorHandling

[<AutoOpen>]
module General =
    let now = DateTime.Now.ToLongTimeString()
    let logme label logThis = 
        printfn "%s %s: %O" now label logThis
    let flip f x y = f y x
    let asSingleton data = [ data ]
    
    let ifTrue trueFunc = function
        | true -> Some trueFunc
        | false -> None
    let toString value = 
        match box value with
        | :? string as s -> s
        | :? bool as b -> sprintf "%b" b
        | :? DateTime as d -> d.ToShortDateString()  
        | other -> sprintf "%A" other    
    module Option =
        let either fSome fNone = function
            | Some s -> fSome s
            | None -> fNone
    module NiceSort =
    //this was genius...
        open System.Text.RegularExpressions
        let convert (text:string) =
            match Double.TryParse(text) with
            | true, n -> Choice1Of2 n
            | false, _ -> Choice2Of2 text
        let alphanumKey key =
            Regex("([0-9]+)").Split(key) |> Array.map convert
        let sortNicely sortBy = List.sortBy alphanumKey sortBy

   //Module extensions   
    module String =
        let newLine = Environment.NewLine
        let replace (subStr:string) withStr (str:string) = str.Replace (subStr, withStr)
        let remove subStr (str:string) = str.Replace(subStr, "")
        let removeStrings (str:string) subStrs=
            subStrs |> List.fold (fun s sub -> remove sub s) str
        let asString value = 
            toString value      
    
module Domain =
    module String =       
        let (|NullOrEmpty|_|) =
            String.IsNullOrWhiteSpace
            >> ifTrue NullOrEmpty

        let (|StringTooLong|_|) maxAllowed s =
            (String.length s) > maxAllowed
            |> ifTrue StringTooLong

        let (|StringTooShort|_|) minAllowed s =
            (String.length s) < minAllowed
            |> ifTrue StringTooShort

        type MaxChars = int
        type MinChars = int

        type StringError =
            | TooManyChars of MaxChars
            | TooFewChars of MinChars
            | EmptyString
            | InvalidPattern

        type String50 = String50 of string with
            member x.AsString = let (String50 n) = x in n
            override x.ToString() = x.AsString
        module String50 =
            [<Literal>]
            let private MaxLen = 50
            let fromOptionToBlank = function
                | Some (s:String50) -> s.AsString
                | None -> ""

            let tryCreate = function
                | NullOrEmpty -> Error EmptyString
                | StringTooLong MaxLen -> Error (TooManyChars MaxLen)
                | nm -> String50 nm |> Ok

            let value (String50 str) = str

            let toLower str50 =
                str50 |> value |> fun s -> s.ToLower()
            let contains str50 (srchVal:string) =
                str50 |> toLower |> fun s -> s.Contains(srchVal.ToLower())

        type String100 = String100 of string with
            member x.AsString = let (String100 n) = x in n
            override x.ToString() = x.AsString
        module String100 =
            [<Literal>]
            let private MaxLen = 100
            let fromOptionToBlank = function
                | Some (s:String100) -> s.AsString
                | None -> ""

            let tryCreate = function
                | NullOrEmpty -> Error EmptyString
                | StringTooLong MaxLen -> Error (TooManyChars MaxLen)
                | nm -> String100 nm |> Ok

            let value (String100 str) = str

            let toLower str100 =
                str100 |> value |> fun s -> s.ToLower()
            let contains str100 (srchVal:string) =
                str100 |> toLower |> fun s -> s.Contains(srchVal.ToLower())            

    module Number =
        let inline (|IsNotANum|_|) n =
            ifTrue IsNotANum (Double.IsNaN n)
        let inline (|IsNegative|_|) n =
            ifTrue IsNegative (n < LanguagePrimitives.GenericZero)
        let inline (|NumTooLarge|_|) maxNum n =
            (n > maxNum) |> ifTrue NumTooLarge
        let inline (|NumTooSmall|_|) minNum n =
            (n < minNum) |> ifTrue NumTooSmall
        let inline (|NumIsZero|_|) n =
            n = LanguagePrimitives.GenericZero
            |> ifTrue NumIsZero

        // let inline(|ContainsBelowMin|_|) minVal d =
        //     let min = MapTypes.min d |> Result.map (fun v -> v < minVal) |> Result.defaultValue false
        //     ifTrue ContainsBelowMin (min)
        // let (|ContainsNegative|_|) = function
        //     | ContainsBelowMin 0. -> Some ContainsNegative
        //         | _ -> None
        type NumberError =
            | TooLargeNum
            | TooSmallNum
            | NumZero

[<AutoOpen>]
module ReactFelizHelpers =
    open Feliz
    open Fable.React
    open Fable.Core.JsInterop
    open Zanaptak.TypedCssClasses
    type BulmaCss = CssClasses<"https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.4/css/bulma.min.css", Naming.PascalCase>
    type FA = CssClasses<"https://use.fontawesome.com/releases/v5.6.3/css/all.css", Naming.CamelCase>
    
    let delayedPromise (func, args, delay) =
        promise {
            logme "Promise sleep was called" ""
            do! Promise.sleep delay
            return! func args }
    type Prop =
        | Prop of IReactProperty
        member x.Value =
            let (Prop p) = x in p
        member x.AppendProps props = props @ [x.Value]
    module Prop =
        let value (Prop p) = p
        let toReactProp (p:Prop) = p |> value
        let none = Prop (prop.alt "")
    type Props =
        | Props of IReactProperty list
        member x.Value =
            let (Props p) = x in p
        member x.AppendProps props = props @ x.Value
        member x.AppendProp prop = [prop] @ x.Value
    module Props =
        let value (Props p) = p
        let toReactProp (p:Props) = p |> value
        let empty = Props []
        let addProps (props: Props) currProps = currProps @ props.Value

    type PropClasses = | PropClasses of string list with
        member x.AsStringList = let (PropClasses l) = x in l
        member x.AsProps = x.AsStringList |> prop.classes |> asSingleton |> Props
           
    module PropClasses =
        let value (pc:PropClasses) = pc.AsStringList
        let asProps (pc:PropClasses) = pc.AsProps
        let addPropClasses (newPc:PropClasses) (currPc:PropClasses) = 
            currPc.AsStringList @ newPc.AsStringList 
            |> PropClasses
        let none = [""] |> PropClasses
    
    //Extending Feliz for some missing interops
    type Html =
        static member inline thead xs = Interop.createElement "thead" xs
        static member inline thead (children: #seq<ReactElement>) =
            Interop.reactElement "thead"
                (createObj [ "children" ==> Interop.reactApi.Children.toArray (unbox<ReactElement list> children) ])
    
    let appendElement newElem prevElems =
        prevElems @ [newElem]
    let appendElements newElems prevElems =
        prevElems @ newElems      
    //logging to console helper
    type StatusMsg =
        | StatusMsg of string
        static member New msg obj = StatusMsg(sprintf "%O: %s: %A" DateTime.Now msg obj)
        member x.AsString = let (StatusMsg s) = x in s
        member x.ToConsole = printfn "%s" x.AsString
    module StatusMsg =
        let consoleOut (s:StatusMsg) = s.ToConsole
    
    //React and Elmish helpers
    let sendMsg dispatch msg = fun _ -> dispatch msg
 
    //React building helpers
    type ReactBuilder = IReactProperty list -> ReactElement
    
    let inline htmlFunc htmlTag (content:IReactProperty list): ReactElement =
        htmlTag content
    let inline htmlPropsFunc htmlTag (propClasses:PropClasses) (props:Props) =
        htmlTag propClasses.AsProps |> props.AppendProps
   
    let propChildren (children: ReactElement) = prop.children children |> asSingleton

    
    type DataLabel =
        | DataLabel of string
        member x.AsString =
            let (DataLabel l) = x in l
    module DataLabel =
        let create lbl =
            let cleanOption str = [ "Some("; "None("; ")" ] |> String.removeStrings str
            let cleanNull (str: string) =
                if str.ToLower().Contains("null") then "No Info"
                else str
            lbl
            |> toString
            |> cleanOption
            |> cleanNull
            |> DataLabel
        let blank = DataLabel ""
        let value (DataLabel l) = l