// import web3 from "./web3";

export function fromWeiToEther(wei) {
    return web3.utils.fromWei(wei.toString(), "ether");
  }
export function toWeiFromEther(ether) {
    return web3.utils.toWei(ether.toString(), "ether");
  }
  
export const myNetwork = sessionStorage.myNetwork
export const myWallet = sessionStorage.myWallet
 