import Web3 from 'web3'

const metamask = {
    ENABLED: 'enabled',
    ERRORED: 'errored',
    NOTFOUND: 'notfound'
}
function getMetamaskStatus() {    
    return (sessionStorage.metamaskStatus)
}
function setMetamaskStatus(status){
    sessionStorage.setItem("metamaskStatus", status)
}
function setMetamaskInfo(){
    let mmStatus = getMetamaskStatus()
    if(mmStatus == metamask.ENABLED){
        sessionStorage.setItem("myNetwork", ethereum.networkVersion)
        sessionStorage.setItem("myWallet", ethereum.selectedAddress)
    } 
    else {
        sessionStorage.setItem("myNetwork", "metamask " + mmStatus)
        sessionStorage.setItem("myWallet",  "metamask " + mmStatus)
    }
}

const myWallet = sessionStorage.myWallet
const myNetwork = sessionStorage.myNetwork
function ethWallet() {
    if(getMetamaskStatus() == metamask.ENABLED) 
        return(ethereum.selectedAddress)
    else 
        return("no metamask found")
}

async function estimateBlock(targetTimestamp) {
    const target = Math.round(targetTimestamp/1000)
    const currBlockNum = await web3.eth.getBlockNumber()
    const currBlock = await web3.eth.getBlock(currBlockNum)
    const estBlockNum = Math.max(1,currBlockNum-Math.round((currBlock.timestamp - target)/15))
    const estBlock = await web3.eth.getBlock(estBlockNum)
    const estBlockTimestamp = new Date(estBlock.timestamp*1000)
    console.log("Starting Block Timestamp: " + estBlockTimestamp)
    sessionStorage.setItem("fromBlock", estBlockNum)
    return estBlockNum
}

async function enableMetamask(){
    if (window.ethereum) {
        window.web3 = new Web3(ethereum);
        try {
            // Request account access if needed
            await ethereum.enable();
            estimateBlock(new Date("2020-03-01"))
            setMetamaskStatus(metamask.ENABLED) 
            console.log("metamask enabled")                       
        } catch (error) {
            console.log('problem loading: ', error)
            setMetamaskStatus(metamask.ERRORED)
        }
    }
    else if (window.web3) {
        window.web3 = new Web3(web3.currentProvider);
        setMetamaskStatus(metamask.ENABLED)  
    }
    else {
        console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
        setMetamaskStatus(metamask.NOTFOUND)
    }
    setMetamaskInfo()
}

window.addEventListener('load', async () => {
    enableMetamask()
});


if(getMetamaskStatus() == metamask.ENABLED) {
    window.web3 = new Web3(ethereum)
    window.ethereum.on('accountsChanged', function (accounts) {    
        if(accounts[0] != myWallet) {      
            console.log(myWallet + " does not equal " + accounts[0])    
            setMetamaskInfo()
            window.location.reload()
        }
    });
    window.ethereum.on('networkChanged', function (networkId) {    
        if(networkId != myNetwork) {                  
            console.log("changing networks: ", networkId, " - ", myNetwork)    
            setMetamaskInfo()
            estimateBlock(new Date("2020-03-01"))
            window.location.reload()// = "#"
            
        }
    });
}

export {myWallet, myNetwork, ethWallet, enableMetamask, getMetamaskStatus}
