namespace Domain

open System
open Ethereum
module FrendLends =
    type ContractAddress =
        | FOUAddr of EthAddress
        | FrendLendAddr of EthAddress
    
    type Transfer = { 
        From : EthAddress; 
        To : EthAddress; 
        Amount : Ether;
        Created : DateTime }     
    module Transfer =
        let empty = {
            To = EthAddress.empty
            From = EthAddress.empty
            Amount = Ether 0.
            Created = DateTime.Now }
        let fromPrims toAddr fromAddr amt created = 
            { To = toAddr |> EthAddress
              From = fromAddr |> EthAddress
              Amount = amt |> Ether
              Created = created }       
    type FrendLendMsg = { Message : string; Created: DateTime}    
    type FundingDecision =
        | Funded of Transfer
        | Rejected of FrendLendMsg
        | Pending
        with 
        member x.AmountFunded =
            match x with
            | Funded t -> t.Amount
            | _ -> Ether 0.
    type FOUStatus = 
        Pending = 0 | Funded = 1 | Rejected = 2 | Repaid = 3
    module FOUStatus = 
        let asString = function
            | FOUStatus.Pending -> "Pending"
            | FOUStatus.Funded -> "Funded"
            | FOUStatus.Rejected -> "Rejected"
            | FOUStatus.Repaid -> "Repaid"
            | _ -> "Unknown Status"
    type FrendOweYou = {
        Address : EthAddress     
        FrendLend : EthAddress
        Lender : EthAddress   
        Recipient : EthAddress
        Requested : Ether       
        Funding : FundingDecision 
        Repaid : Ether
        Status : FOUStatus
        Payments : Transfer list
        Created : DateTime } with
        member x.IsFundable = 
            x.Status = FOUStatus.Pending
        member x.IsRepayable = 
            match x.Funding with
            | Funded t -> t.Amount > x.Repaid
            | _ -> false
    module FrendOweYou =
        let create addr frendLend lender recipient requested repaid status funding payments created  =
            { Address = addr
              FrendLend = frendLend
              Lender = lender
              Recipient = recipient
              Requested = requested
              Funding = funding
              Repaid = repaid
              Status = status
              Payments = payments
              Created = created } 
        let empty =
            { Address = EthAddress.empty
              FrendLend = EthAddress.empty
              Lender = EthAddress.empty
              Recipient = EthAddress.empty
              Requested = Ether 0.
              Funding = Pending
              Repaid = Ether 0.
              Status = FOUStatus.Pending
              Payments = []
              Created = DateTime.Now }                
        let fromPrims addr frendLend lender recipient requested repaid status =            
            create 
                (addr |> EthAddress) (frendLend |> EthAddress) (lender |> EthAddress) 
                (recipient |> EthAddress) (requested |> Ether) (repaid |> Ether) (enum status)
    type FrendLend = {
        Address : EthAddress 
        LenderAddress : EthAddress 
        FrendOweYous : FrendOweYou list }
        with
        member x.FindFou addr =
            x.FrendOweYous |> List.tryFind (fun f -> f.Address = addr)
    module FrendLend =
        let create flAddr lenderAddr fous =
            { Address = flAddr 
              LenderAddress = lenderAddr 
              FrendOweYous = fous }           
        let fromPrims addr lenderAddr =
            create (addr |> EthAddress) (lenderAddr |> EthAddress)                        
        let empty = 
            let emptyEth = EthAddress.init
            create emptyEth emptyEth []
        let getAddress (fl:FrendLend) = fl.Address
        let findFou (fl:FrendLend) fouAddr = fl.FindFou fouAddr

    type WalletActivity = 
        { Wallet : EthAddress
          FrendLends : FrendLend list
          FrendOweYous : FrendOweYou list }
    module WalletActivity =
        let create wallet frendLends fous =  
            {Wallet = wallet; FrendLends = frendLends; FrendOweYous = fous}    
        let init = create EthAddress.empty [] []        
        let fromPrims wallet =
            create (wallet |> EthAddress)
          