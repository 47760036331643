//import {web3} from "./web3"
import FrendOweYou from "../../truffle/build/contracts/FrendOweYou.json";
import FrendLend from "../../truffle/build/contracts/FrendLend.json";
import FrendLendFactory from "../../truffle/build/contracts/FrendLendFactory.json";

export function getFouContract(address) {
    return(new web3.eth.Contract(FrendOweYou.abi, address))}

export function getFrendLendContract(address){
    return(new web3.eth.Contract(FrendLend.abi, address))}

export function getFrendLendFactory(address){
    return(new web3.eth.Contract(FrendLendFactory.abi, address))}