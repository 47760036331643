namespace Common.Components
open Feliz.Bulma
open Feliz
open Common
open Common.Domain.String

[<AutoOpen>]
module MiniComps =
    let htmlP (className:string) (text:string) = Html.p [prop.className className; prop.text text] 
    let title (text:string) = htmlP "title" text
    let subTitle (text:string) = htmlP "subTitle" text 
    let content (text:string) = Html.div [prop.className "content"; prop.text text]
    let narrowColumn element =
        Bulma.column [prop.className BulmaCss.IsNarrow; prop.children [element]]
    let boldLabel (text:string) = Html.label [prop.text text; prop.className "label"]    

    let addonFields controls =
        let controlP control =
            Html.p [
                prop.className BulmaCss.Control
                prop.children [control]
            ]
        Html.div [
            prop.className [BulmaCss.Field; BulmaCss.HasAddons]
            prop.children (controls |> List.map controlP)]
    type Icon =
        | SingleArrowUp 
        | SingleArrowDown 
        | DoubleArrowUp
        | DoubleArrowDown 
        | PlusSign
        | User
        | Copy
        with 
        member x.GetProp =
            let toProp (icon:string) = Props [prop.className (sprintf "fas %s" icon)]                     
            match x with
            | SingleArrowUp -> FA.faAngleUp |> toProp
            | SingleArrowDown -> FA.faAngleDown |> toProp
            | DoubleArrowUp -> FA.faAngleUp |> toProp 
            | DoubleArrowDown -> FA.faAngleUp  |> toProp
            | PlusSign -> FA.faPlus |> toProp
            | User -> FA.faUser |> toProp
            | Copy -> "fas fa-copy fa-xs" |> toProp
    module Icon =
        let none : Icon option = None      
        let createSpan (icon:Icon) (iconClasses:PropClasses) =
            Html.span [
                iconClasses.AsProps.Value.Head; 
                prop.children [Html.i icon.GetProp.Value]]      
    module CopyLink =
        let render copyText =
            Html.a [
                prop.style [style.paddingLeft 5; style.color color.gray]
                prop.className [ "copyBtn"]
                prop.custom ("data-clipboard-text", copyText)
                prop.children [Icon.createSpan Copy (PropClasses [BulmaCss.Icon; BulmaCss.IsSmall])]]
    type Link = {
        Name : string
        Text : string
        IsActive : bool
        Url : string 
        Icon : (Icon*PropClasses) option}
    module Link =
        let create name text route isActive icon = 
            { Name = name; Text = text; Url = route;  IsActive = isActive; Icon = icon }      
        let init = create "" "" "" false None
        
        let renderNavLink = React.functionComponent ("navLinkComp", fun (link:Link) ->
            let iconRender = 
                match link.Icon with 
                | Some (icon, propClasses) -> prop.children [Icon.createSpan icon propClasses]
                | None -> prop.text link.Text
            let navBarLink =
                Bulma.navbarLink [
                    navbarLink.isArrowless                    
                    prop.href link.Url
                    iconRender ]            
            Bulma.navbarItemDiv [
                if link.IsActive then navbarItem.isActive
                navbarItem.isTab
                prop.children [navBarLink]
            ])
        let renderAnchor = React.functionComponent ("linkAnchorComp", fun (item:Link) ->
            Html.a [
                prop.href item.Url
                prop.text item.Text ])

        let renderBoxLink = React.functionComponent ("boxLinkComp", fun (item:Link) ->
            Bulma.box [
                Bulma.title item.Name
                renderAnchor item ])

        let renderAnchorProps (props:Props) = React.functionComponent ("buttonAnchorComp", fun (item:Link) ->
            [   prop.href item.Url
                prop.text item.Text ]
            |> props.AppendProps  
            |> htmlFunc Html.a)   
    type Image = {
        Source : string
        Url : string }
    module Image =
        let render image =
            Html.img [prop.src image.Source ]
               
module Forms =
    type LabelValue = { Label : string; Value : string }
    
    module LabelValue =
        let create label value = {Label = label; Value = value}
        let renderElement elem = React.functionComponent("LabelElemComp", fun (lv:LabelValue) ->
            let label = Bulma.label [size.isSize7Mobile; prop.text lv.Label] |> narrowColumn
            Bulma.columns [prop.children [label; elem |> narrowColumn]])
        let render = React.functionComponent("LabelValueComp", fun (lv:LabelValue) ->
            let label = Bulma.label [size.isSize7Mobile; prop.text lv.Label] |> narrowColumn
            let value = Html.p [size.isSize7Mobile; prop.text lv.Value] |> narrowColumn
            Bulma.columns [ prop.children [label; value]])
                
                
module NavbarBuilder =     
    type Brand = {
        Image : Image
        Links : Link list
        Burger : bool } //see index.html for jquery to handle burger
    module Brand =
        let burger  =
            Bulma.navbarBurger [prop.children [Html.span []; Html.span []; Html.span []]]
        let render = React.functionComponent ("navbarBrand", fun (brand:Brand) -> 
            let img = [
                Bulma.navbarItemDiv 
                    [Bulma.navbarLink [
                        navbarLink.isArrowless
                        prop.href brand.Image.Url
                        prop.children [Image.render brand.Image]]]]
            //let links = brand.Links |> List.map Link.renderNavItem 
            let burger =  [if brand.Burger then burger else Html.none]
            Bulma.navbarBrand  (img @ burger )) //links @ burger))
    type Menu = {
        Links : Link list}
    module Menu =
        let render = React.functionComponent ("navbarMenu", fun (menu:Menu) ->
            let menuLinks = 
                menu.Links |> List.map Link.renderNavLink    
            Bulma.navbarMenu (menuLinks))
    type Navbar =
        { Brand : Brand
          Menu : Menu}  
    module Navbar =
        let updateIsActive activeName navbar =
            let links =
                navbar.Menu.Links 
                |> List.map (fun l -> 
                    let isActive = activeName = l.Name
                    {l with IsActive = isActive})   
            {navbar with Menu = {navbar.Menu with Links = links}}                           
        
        let render = React.functionComponent ("navbar", fun (navbar:Navbar) ->
            Html.div [
                Bulma.navbar [ 
                    Brand.render navbar.Brand
                    Bulma.navbarEnd [Menu.render navbar.Menu]]
                ])

module TableBuilder =
    type HeaderCell =
        { Column: DataLabel
          IsRowLabel: bool
          Props: Props }
    module HeaderCell =        
        let create rowLabel props col =
            { Column = col
              IsRowLabel = rowLabel
              Props = props }      
        let init = create false Props.empty (DataLabel "") 
        let value hc = hc.Column         
    type TableHeader =
        { HeaderCells: HeaderCell list
          RowLabels : HeaderCell list
          SortedBy : HeaderCell option
          Props: Props }
    module TableHeader =
        let create props rowLabels sortedBy hCells =           
            { HeaderCells = hCells
              RowLabels = rowLabels
              SortedBy = sortedBy
              Props = props }
        let init =
            { HeaderCells = []
              RowLabels = []
              SortedBy = None
              Props = Props.empty }           
    type TableCell =
        { Row: DataLabel
          Column: DataLabel
          Value: DataLabel
          Element : ReactElement option
          Props: Props }
    module TableCell =
        let create row col value elem props =
            { Row = row
              Column = col
              Value = value
              Element = elem
              Props = props }
        let init = create (DataLabel "") (DataLabel "") (DataLabel "") None Props.empty       
        let value tc = tc.Value
    type TableRow =
        { RowCells: TableCell list
          RowLabels: TableCell list
          RowKey : DataLabel     
          SortByKey : DataLabel     
          IsSelected: bool
          SelectClasses: PropClasses
          Props: Props 
          AllRowCells : TableCell list}
       
    module TableRow =   
        let allRowCells (tr:TableRow) = tr.RowLabels @ tr.RowCells        
        let updateSortKey colLabel tr =
            let getKey = 
                tr.AllRowCells |> List.tryFind (fun c -> c.Column = colLabel)
            match getKey with
            | Some key -> {tr with SortByKey = key.Value}
            | None -> tr
        let create rowCells rowLabels selClasses props rowKey sortByKey = //need to add order for sort
            let key = 
               match rowKey with
               | Some key -> key
               | None -> rowLabels @ rowCells |> List.head |> fun d -> d.Value
            { RowCells = rowCells
              RowLabels = rowLabels
              RowKey = key
              SortByKey = sortByKey
              IsSelected = false
              SelectClasses = selClasses
              Props = props
              AllRowCells = rowLabels @ rowCells }        
        
    type TableBody =
        { Rows: TableRow list
          SelectedRow: TableRow option
          Props: Props }
    module TableBody =
        let updateSelectedRow body selRow =
            match selRow with
            | Some row when (Some row) <> body.SelectedRow ->                 
                let rows = 
                    body.Rows
                    |> List.map (fun r -> 
                        if r = row then {r with IsSelected = true} else {r with IsSelected=false})
                {body with Rows = rows; SelectedRow= Some {row with IsSelected=true}}
            | Some row when (Some row) = body.SelectedRow ->                 
                let rows = 
                    body.Rows
                    |> List.map (fun r -> 
                        {r with IsSelected = false})
                {body with Rows = rows; SelectedRow =None}                                    
            | _ -> body   
        let sortByKey (rows:TableRow list) =
            rows 
            |> List.sortByDescending (fun r -> NiceSort.alphanumKey r.SortByKey.AsString)          
        let findRowByKey key (body:TableBody) =
            body.Rows |> List.tryFind (fun r -> r.RowKey = key)                    
        let sortRows byCol rows =
            rows |> List.map (TableRow.updateSortKey byCol)
            |> sortByKey
        let create props rows =                                 
            { Rows = rows |> sortByKey
              SelectedRow = None
              Props = props }   
        let init = create Props.empty List.empty
    
    type TableInfo =
        { Header: TableHeader
          Body: TableBody
          Props: Props  
          Title: DataLabel }
    module TableInfo =
        let create header body props =
            {  Body = body
               Header = header
               Props = props 
               Title = DataLabel.blank } 
        let init = 
            create 
                TableHeader.init TableBody.init Props.empty 
       
    type TableMsg =
    | RowSelected of TableRow
    | HeaderClicked of HeaderCell    
        
    let updateTable tableInfo = function
        | RowSelected row ->
            let newBody = TableBody.updateSelectedRow tableInfo.Body  (Some row)
            //logMe "Table Row Clicked" newBody
            {tableInfo with Body = newBody}
        | HeaderClicked hCell ->             
            let newHeader = {tableInfo.Header with SortedBy = Some hCell}
            //logMe "Table Header Clicked:" newHeader
            let newBody = 
                { tableInfo.Body with 
                    Rows = (TableBody.sortRows hCell.Column tableInfo.Body.Rows)}
            {tableInfo with Header = newHeader; Body = newBody}
    let private onClickProp dispatch clickMsg tgt  = 
        match clickMsg with
        | Some msg -> Props [prop.onClick (sendMsg dispatch (msg tgt))]
        | None -> Props.empty
    //INJECT EVENTS
    let renderTable cellClick rowClick headClick info dispatch =            
        let tableCell tc = 
            let onClick = 
                onClickProp dispatch cellClick tc  
            
            match tc.Element with
            | Some elem -> [prop.children elem]
            | None -> [ prop.text tc.Value.AsString ]
            |> tc.Props.AppendProps |> onClick.AppendProps
            |> Html.td     
        let tableRow row =
            let onClick = 
                match rowClick with
                | Some click -> prop.onClick (click row) |> Prop
                | None -> Prop.none
            [ prop.children (row |> TableRow.allRowCells |> List.map tableCell)]                 
            |> if row.IsSelected then row.SelectClasses.AsProps.AppendProps else Props.empty.AppendProps
            |> onClick.AppendProps |> row.Props.AppendProps 
            |> Html.tr
        let headerCell (hc:HeaderCell) =
            let onClick =
                match headClick with
                | Some click -> prop.onClick (click hc) |> Prop
                | None -> Prop.none
            // let onClick = 
            //     onClickProp dispatch headClick hc
            Html.th ([ prop.text hc.Column.AsString ] |> onClick.AppendProps|> hc.Props.AppendProps)
        let tableHeader (head:TableHeader) =
            head.RowLabels @ head.HeaderCells 
            |> List.map headerCell
            |> Html.tr 
            |> propChildren
            |> htmlFunc Html.thead
        let tableBody body =    
            body.Rows 
            |> List.map tableRow   
            |> prop.children |> asSingleton
            |> fun p -> htmlFunc Html.tbody (body.Props.AppendProps p) 
        [ prop.children [
            tableHeader info.Header
            tableBody info.Body]]
        |> info.Props.AppendProps
        |> Html.table

    let renderTableComp cellClick rowClick headClick =
        React.functionComponent("TableComp", fun (props:TableInfo) ->
            let (model, dispatch) = React.useReducer(updateTable, props)            
            renderTable cellClick rowClick headClick model dispatch)                    
        