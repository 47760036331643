namespace Pages

open System
open Feliz
open Elmish
open Feliz.ElmishComponents
open Feliz.Router
open Feliz.Bulma
open Domain.Ethereum
open Domain.FrendLends
open Fable.Core
open Common.Interop.Ethereum
open Common.Components
open Common
open Common.Interop.Promises
open TableBuilder
open CustomComps.AddressLabel

module FrendLend =    
    let fouTableInfo fous =
        let headerData = 
            [ "Request Created"; "Borrower"; "Request (Ether)"; "Status" ]
            |> List.map (DataLabel >> HeaderCell.create false Props.empty )
            |> TableHeader.create (Props [prop.className "title"]) [] None                  
        let rowData =
            fous 
            |> List.map (fun f -> 
                let dateToString (dt:DateTime) = dt.ToString("MM/dd/yyyy HH:mm:ss")
                let justValue value =
                    {TableCell.init with Value = DataLabel value}
                let borrower =
                    if f.Recipient = (EthAddress.fromOption web3.myWallet) then "Current Wallet"                    
                    else f.Recipient.AsShortString                       
                let rowCells =
                    [  (f.Created |> dateToString)
                       borrower
                       f.Requested.AsString 
                       (f.Status |> FOUStatus.asString)]
                    |> List.map justValue      
                let rowKey = DataLabel f.Address.AsString          
                let sortByKey = DataLabel (f.Created |> dateToString)
                TableRow.create
                    rowCells [] (PropClasses [BulmaCss.IsSelected]) Props.empty (Some rowKey) sortByKey)   
        let bodyData = TableBody.create Props.empty rowData                      
        TableInfo.create 
                headerData bodyData (Props [prop.classes [BulmaCss.Table; BulmaCss.IsStriped]])
        
    module InputButtons =        
        let init = PromiseModel.init Cmd.none ("", 0.) ""
        let update func respCmd (msg) (model) = 
            //let respCmd = Router.navigate("frendlend", addr.AsString)
            PromiseModel.update func respCmd msg model
        let renderInputButton (addr:EthAddress) respCmd func (btnText:string) compName =
            let render model dispatch  =    
                let inputBtnComp = React.functionComponent("inputButtonComp", fun () ->
                    let (text, setText) = React.useState "0.000"
                    let button amtStr =
                        let amt = if amtStr = "" then 0.0 else float amtStr 
                        let loading = 
                            if model.Status = Requesting then 
                                logme "model status" model.Status
                                BulmaCss.IsLoading 
                            else 
                                ""                           
                        [ prop.classes [BulmaCss.IsPrimary; BulmaCss.Button; loading]
                          prop.onClick (sendMsg dispatch (MakeRequest (addr.AsString, amt)))
                          prop.text btnText ] 
                        |> htmlFunc Html.button 
                        |> Bulma.control 
                    let input =
                        Html.input [
                            prop.className [BulmaCss.Input; ]
                            prop.type' "number"; prop.step 0.001; prop.min 0.
                            prop.valueOrDefault text
                            prop.onChange setText  ]     
                    let field =
                        Bulma.field [
                            field.hasAddons
                            prop.children [input; button text]]
                        |> narrowColumn                        
                    Bulma.columns [field])        
                inputBtnComp ()        
            React.elmishComponent(compName, init, update func respCmd, render)    
    
    type PageAction =
        | FundFou
        | RejectFou
        | NoAction
    type Msg =
        | LoadFrendLend of EthAddress
        | FrendLendLoaded of IFrendLend option   
        | FouRowSelected of TableRow
        | ButtonClicked of (string -> JS.Promise<string>)*string*PageAction
        | ClickedButton of string
        | Failure of exn
    type Model = { 
        FrendLend : FrendLend option 
        FouTableInfo : TableInfo
        SelectedFou : EthAddress option
        PageAction : PageAction
        CurrentWallet : EthAddress } with
        member x.GetFrendLend = 
            match x.FrendLend with
            | Some fl -> fl
            | None -> FrendLend.empty
        member x.Lender = x.GetFrendLend.LenderAddress
        member x.SelectedFouAddress = x.SelectedFou |> Option.defaultValue EthAddress.empty
        member x.CurrentIsLender = x.Lender = x.CurrentWallet        
        member x.GetSelectedFou = 
           match x.GetFrendLend.FindFou x.SelectedFouAddress with
           | Some fou -> fou
           | None -> FrendOweYou.empty
        member x.CurrentIsRecipient = 
            x.CurrentWallet = x.GetSelectedFou.Recipient       
            
    let init (flAddr, fouAddr) = 
        { FrendLend = None
          FouTableInfo = TableInfo.init
          SelectedFou = fouAddr
          PageAction = NoAction
          CurrentWallet = EthAddress.empty}, 
        Cmd.ofMsg (LoadFrendLend flAddr)
    let navigate (flAddr:EthAddress) (fouAddr:EthAddress option) =
        match fouAddr with
        | Some fou -> 
            Router.navigate("frendlend", flAddr.AsString, ["fou", fou.AsString])
        | None -> Router.navigate("frendlend", flAddr.AsString)    
    

    let update msg model =
        let reload addr = Cmd.ofMsg (LoadFrendLend addr)
        match msg with 
        | LoadFrendLend flAddr ->            
            model, 
            Cmd.OfPromise.either delayedPromise (getFrendLend, flAddr.AsString, 400) FrendLendLoaded Failure 
        | FrendLendLoaded resp ->
            let newModel = 
                match resp with
                | Some fl ->  
                    let initTableInfo = fouTableInfo fl.toDomain.FrendOweYous
                    let newFouTableInfo, selFou = 
                        match model.SelectedFou with
                        | Some fou -> 
                            initTableInfo.Body
                            |> TableBody.findRowByKey (DataLabel fou.AsString)                            
                        | None -> 
                            initTableInfo.Body.Rows |> List.tryHead
                        |> Option.either (fun tr -> 
                            updateTable initTableInfo (RowSelected tr), 
                            Some (tr.RowKey.AsEthAddress)) (initTableInfo, None)
                    {model with 
                        FrendLend = Some fl.toDomain
                        FouTableInfo = newFouTableInfo 
                        SelectedFou = selFou
                        CurrentWallet = web3.myWallet |> EthAddress.fromOption}                     
                | None -> model                     
            newModel, Cmd.none     
        | FouRowSelected tr ->            
            let selFou = tr.RowKey.AsEthAddress |> Some                 
            let navCmd, newFouTableInfo = 
                if selFou <> model.SelectedFou then
                    navigate model.GetFrendLend.Address selFou,
                    updateTable model.FouTableInfo (RowSelected tr)
                else Cmd.none, model.FouTableInfo
            {model with 
                SelectedFou = selFou; 
                FouTableInfo = newFouTableInfo}, 
            navCmd //Cmd.none
        | ButtonClicked (func, addr, action) ->
            {model with PageAction = action}, Cmd.OfPromise.either func addr ClickedButton Failure
        | ClickedButton _ ->
            {model with PageAction = NoAction}, reload model.GetFrendLend.Address  
        | Failure e -> 
            logme "Failure loading frend lend" e
            {model with FrendLend = None}, Cmd.none
    
    open Forms
    let renderFouDetails model dispatch =
        let selFou = 
            model.FrendLend 
            |> Option.bind (fun fl -> 
                model.SelectedFou 
                |> Option.bind (FrendLend.findFou fl))
        match selFou with
        | Some fou ->
            logme "Payments" fou.Payments
            
            let borrower =
                if model.CurrentIsRecipient then "Current Wallet"                    
                else fou.Recipient.AsShortString             
            let fouTitle =
               title (sprintf "FrendOweYou Contract (%s)" fou.Address.AsShortString)   
            let fouInfo =
                [ LabelValue.create "Contract Address:" fou.Address.AsShortString
                  LabelValue.create "Borrower Wallet:" borrower
                  LabelValue.create "Status:" (fou.Status |> FOUStatus.asString)
                  LabelValue.create "Amount Requested (Ether):" (fou.Requested.AsString)
                  LabelValue.create "Amount Funded (Ether):" (fou.Funding.AmountFunded.AsString)
                  LabelValue.create "Amount Repaid (Ether):" (fou.Repaid.AsString)]           
                |> List.map LabelValue.render            
            let fundBtn = 
                logme "is lender" model.Lender
                if model.GetSelectedFou.IsFundable && model.CurrentIsLender then
                    let loading = if model.PageAction = FundFou then BulmaCss.IsLoading else ""
                    Html.button [
                        prop.classes [BulmaCss.IsSuccess; BulmaCss.Button; loading]
                        prop.onClick (sendMsg dispatch (ButtonClicked (fundFou, fou.Address.AsString, FundFou)))
                        prop.text "Fund Request"]             
                else Html.none                        
            let rejectBtn = 
                if model.GetSelectedFou.IsFundable && model.CurrentIsLender then
                    let loading = if model.PageAction = RejectFou then BulmaCss.IsLoading else ""
                    Html.button [
                        prop.classes [BulmaCss.IsDanger; BulmaCss.Button; loading]
                        prop.onClick (sendMsg dispatch (ButtonClicked (rejectFou, fou.Address.AsString, RejectFou)))
                        prop.text "Reject Request"]     
                else Html.none                           
            let paymentBtn = 
                if model.GetSelectedFou.IsRepayable && model.CurrentIsRecipient then 
                    let respCmd = 
                        navigate model.GetFrendLend.Address (Some fou.Address) // model.Repayable then
                    InputButtons.renderInputButton 
                        fou.Address respCmd repayFou "Make Payment" "paymentComp"                               
                else Html.none           
            
            Html.div 
                ([fouTitle] 
                 |> appendElements fouInfo 
                 |> appendElements [fundBtn; rejectBtn; paymentBtn])
        | None -> title "FrendOweYou Details" 
    
    let render (model:Model) dispatch =
        match model.FrendLend with
        | Some fl ->            
            let flTitle =
               Bulma.title (sprintf "FrendLend Contract (%s)" fl.Address.AsShortString)                 
            let info = [
                Bulma.columns [ 
                    prop.children [
                        boldLabel "FrendLend Contract Address: " |> narrowColumn
                        fl.Address.Render (EthContract FrendLendC) |> narrowColumn]]
                Bulma.columns [ 
                    prop.children [
                        boldLabel "FrendLend Owner Wallet: " |> narrowColumn
                        fl.LenderAddress.Render Wallet |> narrowColumn]]]                        
            let newFou =
                let respCmd = 
                    navigate model.GetFrendLend.Address model.SelectedFou
                InputButtons.renderInputButton 
                    fl.Address respCmd createFou "New Request (Ether)" "fouComp" 
            let fouDetail =
                renderFouDetails model dispatch                    
            let frendOweYous =
                let onRowClick = Some (fun row -> sendMsg dispatch (FouRowSelected row))       
                
                Bulma.columns [ 
                    prop.children [
                        Html.div [
                            title "Existing FrendOweYou Requests:" 
                            renderTableComp None onRowClick None model.FouTableInfo 
                            newFou ] 
                            |> narrowColumn                    
                        fouDetail |> narrowColumn ]]
            let allElems =
                [flTitle] @ info @ [Html.hr []; frendOweYous;  ]
            Html.div[Bulma.section [Bulma.container allElems]]            
                              
        | None -> Html.button [prop.classes [BulmaCss.Button; BulmaCss.IsLoading]]
    let elmishComp addresses = React.elmishComponent("FlDetailsComponent", init addresses, update, render)            